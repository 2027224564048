

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
  
@font-face {
    font-family: "Inria";
    src: local("Inria"), url('./assets/fonts/InriaSans/InriaSans-Regular.ttf') format("truetype");
 }

 @font-face {
    font-family: "Inria-Bold";
    src: local("Inria-Bold"), url('./assets/fonts/InriaSans/InriaSans-Bold.ttf') format("truetype");
 }

 @font-face {
    font-family: "Inter";
    src: local("Inter"), url('./assets/fonts/Inter/Inter-Regular.ttf') format("truetype");
 }

 @font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"), url('./assets/fonts/Inter/Inter-Bold.ttf') format("truetype");
 }

.ui-popup-success{
   border: 2px solid #a5dc86 !important;
   max-width: 900px !important;
}
 
.ui-popup-warning{
    border: 2px solid #facea8 !important;
    max-width: 900px;
}

.ui-popup-error{
    border: 2px solid #f27474 !important;
    max-width: 900px;
}

.ui-popup-info{
    border: 2px solid #9de0f6 !important;
    max-width: 900px;
}

.ui-popup-question{
    border: 2px solid #87adbd !important;
    max-width: 900px;
}
